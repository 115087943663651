/* @import '@nrk/nrk-sans/nrk-sans.css'; */

.root {
}

.app {
  font-family: 'NRK Sans Variable', sans-serif;
  /* font-family: 'LFT Etica', sans-serif;*/
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);

  @media screen and (max-width: 700px) {
    padding-bottom: 0.4rem;
  }
}

:global(html.darkmode) .app {
  background: #26292a;
}

.wrap {
  /* padding: 0 2rem; */
  position: relative;
}

.scrollWrap {
  /* overflow: hidden; */
  padding: 2px 0;
}

.langlesingscroll {
  display: flex;
  margin: 0 -0.5rem;
  /* prevent safari scrollbar bug */
  padding: 3px 0 10px 0;
  -ms-overflow-style: none;
}

.plug {
  flex: 0 0 auto;
  display: flex;
  width: 16rem;
  height: calc(16rem * (2 / 1.5));
  margin: 0 0.3rem;
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.spacerElm {
  width: 0.3rem;
  /* width: 2rem; */
  flex: 0 0 auto;
}

.mainTitle {
  font-size: 1.2em;
  text-align: left;
  padding: 15px 15px 10px 13px;
  margin: 0;
  /* border-left: 6px solid #260859; */
}

.link {
  flex: 1;
  position: relative;

  &:focus {
    box-shadow: 0 0 0 2px black;
  }
}

.readMoreLinkWrapper {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 3.6rem; */
  padding: 0.5rem 0 1rem 0;
}

.readMoreLink {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.96rem;
  font-weight: 500;
  /* width: calc(80% - 3.7rem); */
  /* max-width: 400px; */
  /* height: 3rem; */
  /* height: calc(16rem * (2 / 1.5)); */
  overflow: hidden;
  /* margin: 0.3rem auto; */
  padding: 0.4rem 1.5rem;
  /* padding-top: 60px; */
  color: #fff;
  border: 1px solid #1767ce;
  border-radius: 90px;
  background: #1767ce;
  text-decoration: none;
  text-align: center;
  font-variant-ligatures: none;
  /* border-radius: 30px; */
  cursor: pointer;

  & svg {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 0 0 0 0.6rem;
  }

  &:hover {
    background: #fff;
    color: #1767ce;
  }
}

.inlineReadMoreLink {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: calc(16rem - 3rem);
  height: calc(16rem * (2 / 1.5));
  overflow: hidden;
  margin: 0 0.3rem;
  padding: 0 1.5rem;
  color: #fff;
  background: #1767ce;
  font-size: 1.5em;
  text-decoration: none;
  text-align: center;

  & svg {
    width: 40px;
    height: 40px;
    padding: 1rem;
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 90%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.text {
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #000;
  background: #fff;
  text-align: center;
  padding: 0.75rem 0.75rem;
  margin: 90% auto 0 auto;
  font-size: 1.09rem;
  font-weight: 800;
  line-height: 1.1em;
  width: calc(90% - 1rem);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
}

:global(html.darkmode) .text {
  background: #061629;
  color: #fff;
}

.plugshadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
      0deg,
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.738) 19%,
      hsla(0, 0%, 0%, 0.541) 34%,
      hsla(0, 0%, 0%, 0.382) 47%,
      hsla(0, 0%, 0%, 0.278) 56.5%,
      hsla(0, 0%, 0%, 0.194) 65%,
      hsla(0, 0%, 0%, 0.126) 73%,
      hsla(0, 0%, 0%, 0.075) 80.2%,
      hsla(0, 0%, 0%, 0.042) 86.1%,
      hsla(0, 0%, 0%, 0.021) 91%,
      hsla(0, 0%, 0%, 0.008) 95.2%,
      hsla(0, 0%, 0%, 0.002) 98.2%,
      hsla(0, 0%, 0%, 0) 100%
    ),
    linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0));
  background-position: 0 34%, 0 111%;
  background-size: 100% 50%, 100% 40%;
  background-repeat: no-repeat;
}

.button {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: 0 solid;

  @media screen and (min-width: 750px) {
    display: flex;
  }
}

.icon {
  height: 55px;
  width: 55px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  & svg {
    height: 25px;
    width: 25px;
    stroke-width: 0.7px;
    color: #fff;
  }
}

.button:disabled svg {
  color: #aaa;
}

.button:focus {
  outline: none;
  & .icon {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75);
    outline: 0;
  }
}

.buttonLeft {
  composes: button;
  left: 0;
}

.buttonRight {
  composes: button;
  right: 0;
}
